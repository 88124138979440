import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import GlobalStyle from '../styles/globalStyles'
import SEO from '../components/SEO'
import HeroSection from '../components/HeroSection'
import SliceZone from '../components/SliceZone'

const Homepage = ({data}) => {

  const prismicContent = data.allPrismicHomepage.edges[0]
  if (!prismicContent) return null
  const document = prismicContent.node 

  console.log(document) 

  const bannerContent = {
    title: document.data.banner_title,
    description: document.data.banner_description,
  }

  return (
    <Layout isHomepage>
      <GlobalStyle />
      <SEO title="Home" />
      <HeroSection bannerContent={bannerContent} />
      <SliceZone sliceZone={document.data.body} />
    </Layout>
  )
}

export default Homepage

export const query = graphql`
query IndexQuery {
  allPrismicHomepage {
    edges {
      node {
        data {
          banner_title {
            text
          }
          banner_description {
            text
            html
            raw
          }
          body {
            ... on PrismicHomepageBodyText {
              id
              primary {
                content {
                  text
                  raw
                }
              }
              slice_type
            }
            ... on PrismicHomepageBodyQuote {
              id
              slice_type
              primary {
                heading {
                  raw
                }
                quote {
                  text
                }
                cite {
                  text
                }
              }
            }
            ... on PrismicHomepageBodyFullWidthImage {
              id
              primary {
                full_width_image {
                  fixed {
                    width
                  }
                  alt
                  url
                }
              }
              slice_type
            }
            ... on PrismicHomepageBodyImageGallery {
              id
              primary {
                gallery_title {
                  text
                }
              }
              slice_type
              items {
                image {
                  alt
                  url
                }
                image_description {
                  text
                }
                link {
                  type
                  uid
                }
                link_label {
                  text
                }
              }
            }
            ... on PrismicHomepageBodyImageHighlight {
              id
              slice_type
              primary {
                link {
                  type
                  uid
                }
                link_label {
                  text
                }
                description {
                  text
                }
                featured_image {
                  alt
                  url
                }
                title {
                  text
                }
              }
            }
            ... on PrismicHomepageBodyTextMultiColumn {
              id
              primary {
                heading {
                  text
                }
              }
              slice_type
              items {
                content {
                  raw
                  text
                }
              }
            }
            ... on PrismicHomepageBodyTextList {
              id
              slice_type
              primary {
                heading {
                  raw
                }
                intro {
                  raw
                }
              }
              items {
                list_item {
                  raw
                }
              }
            }
          }
        }
      }
    }
  }
}

`