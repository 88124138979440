import React from 'react'
import { RichText } from 'prismic-reactjs'
import GatsbyLink  from '../utils/gatsbyLink'
import { graphql, Link, StaticQuery } from 'gatsby'
import styled from "styled-components";

import Section from './layout/Section'
import Container from './layout/Container'

const StyledSection = styled(Section)`
  padding: 2rem 0; 
`

const StyledContainer = styled(Container)`
  padding: 0;
`

const Banner = styled.div`
  grid-column: 1 / span 6;
  padding: 2rem 0;

  @media ${(props) => props.theme.breakpoints.s} {
    grid-column: 2 / span 7;
  }

  @media ${(props) => props.theme.breakpoints.m} {
    grid-column: 2 / span 5;
  }

  @media ${(props) => props.theme.breakpoints.xl} {
    grid-column: 1 / span 6;
  }
`

const CardList = styled.ul`
  display: flex;
  grid-column: 1 / span 6;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;

  // Settings for slider
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;

  // Hide horizontal scrollbar but keep functionality
  -webkit-scrollbar: display: none;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  @media ${(props) => props.theme.breakpoints.s} {
    grid-column: 2 / span 8;
  }

  @media ${(props) => props.theme.breakpoints.m} {
    grid-column: 8 / span 6;
    flex-direction: column;
    overflow: visible;
  }

  @media ${(props) => props.theme.breakpoints.l} {
    grid-column: 8 / span 6;
  }

  @media ${(props) => props.theme.breakpoints.xl} {
    grid-column: 8 / span 7;
  }
`

const CardItem = styled.li`
  box-shadow: 12px 24px 48px rgba(0,0,0,0.12);
  padding-top: 100%;
  position: relative;
  height: 0;
  width: 100%;

  // Settings for slider
  flex-shrink: 0;
  scroll-snap-align: start;
  margin-right: 16px;

  ::marker {
    font-size: 0;
  }

  a {
    transition: all 0.3s ease; 
  }

  a:hover {
    filter: brightness(95%);
    transition: all 0.3s ease; 
  }

  @media ${(props) => props.theme.breakpoints.s} {
    padding-top: 50%;
    width: 50%;
  }

  @media ${(props) => props.theme.breakpoints.m} {
    margin-bottom: 24px;
    padding-top: 25%;
    width: 100%;
  }
`

const CardImage = styled.img`
  object-fit: cover;
  height: 100%;
  width: 100%;
`

const CardLabel = styled.div`
  background-color: #0096B8; 
  color: #fff;
  font-size: 16px;
  line-height: 36px;
  display: inline-block;
  padding: 0 32px;
  position: absolute;
  bottom: 30px;
  right: 0;
  height:36px;
`

const CardLink = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const Heading = styled.h1`
  color: rgba(0,0,0,0.87);
  font-family: Roboto Slab, serif;
  font-size: 30px;
  font-weight: 700;
  line-height: 1.7;
  margin-top: 48px;
  margin-bottom: 24px;

  @media ${(props) => props.theme.breakpoints.xxs} {
    font-size: 34px;
  }

  @media ${(props) => props.theme.breakpoints.s} {
    font-size: 36px;
    margin-top: 32px;
    margin-bottom: 24px;
  }

  @media ${(props) => props.theme.breakpoints.l} {
    font-size: 48px;
    font-weight: 700;
    margin-top: 48px;
  }
`

const Highlight = styled.span`
  background: #FFDE0A;
  padding: 0.125rem 0;
  -webkit-box-shadow: 0.5rem 0px 0px #FFDE0A, -0.5rem 0px 0px #FFDE0A;
  box-shadow: 0.5rem 0px 0px #FFDE0A, -0.5rem 0px 0px #FFDE0A;
  -webkit-box-decoration-break:clone;
  -moz-box-decoration-break:clone; 
  box-decoration-break: clone;

  @media ${(props) => props.theme.breakpoints.xxs} {
    background: #FFDE0A;
    padding: 0.125rem 0;
    -webkit-box-shadow: 0.5rem 0px 0px #FFDE0A, -0.5rem 0px 0px #FFDE0A;
    box-shadow: 0.5rem 0px 0px #FFDE0A, -0.5rem 0px 0px #FFDE0A;
    -webkit-box-decoration-break:clone;
    -moz-box-decoration-break:clone; 
    box-decoration-break: clone;
  }

  @media ${(props) => props.theme.breakpoints.l} {
    background: #FFDE0A;
    padding: 0.125rem 0;
    -webkit-box-shadow: 1rem 0px 0px #FFDE0A, -1rem 0px 0px #FFDE0A;
    box-shadow: 1rem 0px 0px #FFDE0A, -1rem 0px 0px #FFDE0A;
    -webkit-box-decoration-break:clone;
    -moz-box-decoration-break:clone; 
    box-decoration-break: clone;
  }
`

const Intro = styled.div`
  font-size: 18px;
`

const HeroSection = ({ bannerContent }) => {

  return (
    <StaticQuery
      query={`${cardQuery}`}
      render={(data) => {

        const prismicContent = data.allPrismicCards.edges[0]
        if (!prismicContent) return null
        const doc = prismicContent.node

        return(
          <StyledSection as="section">
            <StyledContainer>
              <Banner>
                <Heading>
                  <Highlight>
                    {bannerContent.title.text}
                  </Highlight>
                </Heading>
                <Intro>
                  <RichText
                    render={bannerContent.description.raw}
                    serializeHyperlink={GatsbyLink}
                  />
                </Intro>
              </Banner>
              <CardList>
                {doc.data.cards.map((card, index) => {
                  return (
                    <CardItem key={`link-${index}`}>
                      <CardLink to={card.card_link.uid}>
                        <CardImage src={card.card_image.url} alt={card.card_label[0].text} />
                        <CardLabel>
                          {card.card_label[0].text}
                        </CardLabel>
                      </CardLink>
                    </CardItem>
                  )
                })}
              </CardList>
            </StyledContainer>
          </StyledSection>
        )
      }}
    />
  )
}

const cardQuery = graphql`
  query cardQuery {
    allPrismicCards {
      edges {
        node {
          data {
            cards {
              card_image {
                url
              }
              card_label {
                text
              }
              card_link {
                uid
              }
            }
          }
        }
      }
    }
  } 
`

export default HeroSection
