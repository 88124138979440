// This Link component is specifically used for RichTextFields from Prismic.
// It allows for Gatsby Link to be created for links within the RichtextField.

import React from 'react'
import { Link } from 'gatsby'
import linkResolver from '../utils/linkResolver'

const GatsbyLink = (type, element, content, children, index) => {

  if (element.data.link_type === 'Document') {
    return (
      <Link to={linkResolver(element.data)} key={element.data.id}>
        {content}
      </Link>
    )
  }
  return null
}

export default GatsbyLink